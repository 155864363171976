import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Snackbar } from '@mui/material'
import { handleMessageClose } from '@/actions/snackbar'

class SnackbarView extends React.Component {
  handleCloseSnackbar = () => {
    const { dispatch } = this.props
    dispatch(handleMessageClose())
  }

  render() {
    const { vertical } = this.props
    const { message, open } = this.props.snackbar

    return (
      <Fragment>
        <Snackbar
          anchorOrigin={{
            vertical,
            horizontal: 'center',
          }}
          open={open}
          autoHideDuration={3500}
          onClose={this.handleCloseSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnackbar}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  snackbar: state.snackbar,
})

export default connect(mapStateToProps)(SnackbarView)
