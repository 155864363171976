import React from 'react'
import { Box } from '@mui/material'
import ArticleTag from '../../atoms/ArticleTag/ArticleTag'

const ArticleTags = (props: any) => {
  const { categories, tags, resins } = props

  return (
    <Box sx={{ mt: '-5px' }}>
      {categories?.map((category: any, index: number) => {
        return (
          <ArticleTag
            data={category}
            key={index}
            type="category"
            pathname={'/category/[category_id]/[pageNum]'}
          />
        )
      })}
      {tags?.map((tag: any, index: number) => {
        return <ArticleTag data={tag} key={index} type="tag" pathname={'/tag/[tag_id]/[pageNum]'} />
      })}
      {resins?.map((resin: any, index: number) => {
        return (
          <ArticleTag
            data={resin}
            key={index}
            type="resin"
            pathname={'/resin/[resin_id]/[pageNum]'}
          />
        )
      })}
    </Box>
  )
}

export default ArticleTags
