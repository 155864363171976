import React from 'react'
import { CardMedia } from '@mui/material/'
import Link from 'next/link'

type Props = {
  id: any
  imageUrl: string
}

export const ArticleFeedImage = (props: Props) => {
  return (
    <Link href={'/news/[id]'} as={`/news/${props.id}`}>
      <div>
        <CardMedia
          sx={{
            display: 'block',
            height: '0',
            pt: '56.25%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          image={props.imageUrl}
        />
      </div>
    </Link>
  )
}
