import React from 'react'
import { Card, CardContent, Box } from '@mui/material'
import ArticleDate from '../../atoms/ArticleDate/ArticleDate'
import ArticleFeedExcerpt from '../../atoms/ArticleFeedExcerpt/ArticleFeedExcerpt'
import { ArticleFeedImage } from '../../atoms/ArticleFeedImage/ArticleFeedImage'
import ArticleFeedTitle from '../../atoms/ArticleFeedTitle/ArticleFeedTitle'
import ArticleTags from '../../molecules/ArticleTags/ArticleTags'

const ArticleFeedCard = (props: any) => {
  const { article } = props
  const image = article?.thumbnailUrl ?? article?.featuredImageUrl?.url ?? '/images/thumb.png'

  return (
    <div>
      <Card sx={{ mt: 1 }}>
        <ArticleFeedImage id={article.wid} imageUrl={image} />
        <CardContent>
          <ArticleFeedTitle id={article.wid} title={article.title} variantType="h6" />
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <ArticleDate date={article.date} />
            <ArticleTags
              categories={article.categories}
              tags={article.tags}
              resins={article.resins}
            />
          </Box>
          <ArticleFeedExcerpt excerpt={article.excerpt} id={article.wid} />
        </CardContent>
      </Card>
    </div>
  )
}

export default ArticleFeedCard
