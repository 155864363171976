import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import numeral from 'numeral'

const Headline = ({ date, elevation, marketData, title }: any) => {
  return (
    <div>
      <Paper
        elevation={elevation}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 0,
          px: 2,
        }}
      >
        <Typography variant="body2" component="h3">
          {title}
        </Typography>
        <Typography variant="body2" component="h3">
          {marketData?.value}
          <Box
            component="span"
            sx={{
              color: marketData?.sign === '+' ? 'green' : 'red',
            }}
          >
            {`${marketData?.sign}${numeral(marketData?.difference).format('0,0.[00]')}`}
          </Box>
        </Typography>
        <Typography variant="body2" component="h3">
          {date}
        </Typography>
      </Paper>
    </div>
  )
}

export default Headline
