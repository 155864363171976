import React from 'react'
import Snackbar from '@/containers/organisms/Snackbar/Snackbar'
import ArticleFeedCard from '@/components/organisms/ArticleFeedCard/ArticleFeedCard'
import ArticleFeedList from '@/components/organisms/ArticleFeedList/ArticleFeedList'
import { MarketLatestResponse } from '@/infrastructure-v2/plabase/market/market'
import ArticleReadMoreButton from './ArticleReadMoreButton'
import { HeadlineAutoPlay } from './HeadlineAutoPlay'

type Props = {
  articles: any[]
  market: MarketLatestResponse
}

const Article = (props: Props) => (
  <>
    <HeadlineAutoPlay market={props.market} />
    {props.articles.length > 0 ? (
      <>
        <ArticleFeedCard article={props.articles[0]} />
        {props.articles.slice(1).map((article, index) => {
          return <ArticleFeedList article={article} key={index} />
        })}
      </>
    ) : null}
    <ArticleReadMoreButton />
    <Snackbar vertical="top" />
  </>
)

export default Article
