export function formatLatest(latest_rate = [], labels) {
  const rate = {}
  const signs = { 1: '+', '-1': '', 0: '' }
  if (!latest_rate || typeof latest_rate.length === 'undefined' || latest_rate.length === 0) {
    return null
  }
  const latestRates = [...latest_rate]
    .sort((a, b) => new Date(b.csvDate) - new Date(a.csvDate))
    .slice(0, 2)
  rate.csvDate = new Date(latestRates[0].csvDate)
  for (const label of labels) {
    rate[label] = {
      difference: 0,
      difference_style: 'rate-prev is-zero',
      sign: '0',
    }
    if (typeof latestRates[0] !== 'undefined' && typeof latestRates[0][label] !== 'undefined') {
      rate[label].value = latestRates[0][label]
      if (latestRates.length > 1) {
        rate[label].difference = latestRates[0][label] - latestRates[1][label]
        if (rate[label].difference !== 0)
          rate[label].difference_style =
            rate[label].difference > 0 ? 'rate-prev is-plus' : 'rate-prev is-minus'
        rate[label].sign = signs[Math.sign(rate[label].difference).toString()]
      }
    }
  }

  return rate
}
