import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { Box, Paper } from '@mui/material'
import moment from 'moment'
import Link from 'next/link'
import { formatLatest } from '@/api/formatLatest'
import Headline from '@/components/molecules/Headline/Headline'
import { MarketLatestResponse } from '@/infrastructure-v2/plabase/market/market'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

type Props = {
  market: MarketLatestResponse
}

export function HeadlineAutoPlay(props: Props) {
  const [index, setIndex] = useState(0)

  const handleChangeIndex = (autoPlayIndex: number) => {
    setIndex(autoPlayIndex)
  }

  if (!(props.market.spotRates && props.market.naphthas && props.market.wtis)) {
    return null
  }

  const formatedSpotRate: any = formatLatest(props.market.spotRates, ['tts', 'ttb'])
  const formatedNaphtha: any = formatLatest(props.market.naphthas, ['value'])
  const formatedWti: any = formatLatest(props.market.wtis, ['price'])

  return (
    <>
      <Box sx={{ position: 'relative', mt: 1 }}>
        <Paper elevation={1}>
          <AutoPlaySwipeableViews index={index} interval={3500} onChangeIndex={handleChangeIndex}>
            <Link href="/spot_rate">
              <Box component="a" sx={{ textDecoration: 'none' }}>
                <Headline
                  title="電信売相場 ドル(円)"
                  marketData={formatedSpotRate.tts}
                  date={moment(formatedSpotRate.csvDate).format('MM/DD')}
                  elevation={0}
                />
              </Box>
            </Link>
            <Link href="/spot_rate">
              <Box component="a" sx={{ textDecoration: 'none' }}>
                <Headline
                  title="電信買相場 ドル(円)"
                  marketData={formatedSpotRate.ttb}
                  date={moment(formatedSpotRate.csvDate).format('MM/DD')}
                  elevation={0}
                />
              </Box>
            </Link>
            <Link href="/naphtha">
              <Box component="a" sx={{ textDecoration: 'none' }}>
                <Headline
                  title="輸入ナフサ 円/kl"
                  marketData={formatedNaphtha.value}
                  date={moment(formatedNaphtha.csvDate).format('MM月')}
                  elevation={0}
                />
              </Box>
            </Link>
            <Link href="/wti">
              <Box component="a" sx={{ textDecoration: 'none' }}>
                <Headline
                  title="原油先物 ドル/bbl"
                  marketData={formatedWti.price}
                  date={moment(formatedWti.csvDate).format('MM/DD')}
                  elevation={0}
                />
              </Box>
            </Link>
          </AutoPlaySwipeableViews>
        </Paper>
      </Box>
    </>
  )
}
