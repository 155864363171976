import React from 'react'
import Typography from '@mui/material/Typography'
import Link from 'next/link'

const ArticleFeedCaptionText = ({ id, title = '', variantType = 'subtitle1' } = {}) => {
  return (
    <Link href={'/news/[id]'} as={`/news/${id}`} style={{ textDecoration: 'none' }}>
      <Typography sx={{ fontWeight: 'bold' }} color="textPrimary" variant={variantType}>
        {title}
      </Typography>
    </Link>
  )
}

export default ArticleFeedCaptionText
