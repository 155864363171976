import React from 'react'
import { findMetaTags } from '@/api/metaTagList'
import Home from '@/containers/template/Home'
import { initializeApollo } from '@/graphql/apolloClient'
import {
  GetArticleAllDocument,
  GetArticleAllQuery,
  GetArticleAllQueryVariables,
} from '@/services/graphcms/client'
import { MarketApi } from '@/infrastructure-v2/plabase/market/market'
import { ItemSearchApi } from '@/infrastructure-v2/plabase/plastic/item/search'

type Props = React.ComponentProps<typeof Home>

export default function Index(props: Props) {
  return (
    <>
      <Home {...props} />
    </>
  )
}

export async function getStaticProps() {
  const apolloClient = initializeApollo()

  const {
    data: { articles },
  } = await apolloClient.query<GetArticleAllQuery, GetArticleAllQueryVariables>({
    query: GetArticleAllDocument,
    variables: {
      skip: 0,
      first: 10,
    },
  })

  const searchConditions = await new ItemSearchApi().findSearchConditions()

  const market = await new MarketApi().findLatest()

  const metaTags = findMetaTags('index')

  return {
    props: {
      articles,
      searchConditions,
      market,
      nextSeo: metaTags,
    },
    revalidate: 600,
  }
}
