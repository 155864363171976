import React, { Fragment } from 'react'
import { Box } from '@mui/material'
import Link from 'next/link'

const ArticleTag = (props: any) => {
  const { data, type, pathname } = props

  return (
    <Fragment>
      <Link href={pathname} as={`/${type}/${data.wid}/1`} style={{ textDecoration: 'none' }}>
        <Box
          component="span"
          sx={{
            mr: '10px',
            color: 'blue',
            textDecoration: 'none',
            fontSize: '12px',
          }}
        >
          {data.name}
        </Box>
      </Link>
    </Fragment>
  )
}

export default ArticleTag
