import React from 'react'
import { Box, Button } from '@mui/material'
import Router from 'next/router'

const ArticleReadMoreButton = () => {
  return (
    <Box sx={{ mt: 2, mb: 5, mx: 1 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          Router.push('/news/page/[pageNum]', '/news/page/2')
        }}
        fullWidth={true}
      >
        もっと見る
      </Button>
    </Box>
  )
}

export default ArticleReadMoreButton
