import React from 'react'
import Typography from '@mui/material/Typography'
import Link from 'next/link'
import truncate from 'truncate'

const ArticleFeedExcerpt = ({ excerpt = '', id } = {}) => {
  return (
    <Link href={'/news/[id]'} as={`/news/${id}`} style={{ textDecoration: 'none' }}>
      <Typography component="p" variant="body2" color="textPrimary">
        {truncate(excerpt, 88)}
      </Typography>
    </Link>
  )
}

export default ArticleFeedExcerpt
