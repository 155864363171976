import React from 'react'
import { Box, Card, CardContent, Grid } from '@mui/material'
import ArticleDate from '../../atoms/ArticleDate/ArticleDate'
import ArticleFeedExcerpt from '../../atoms/ArticleFeedExcerpt/ArticleFeedExcerpt'
import { ArticleFeedImage } from '../../atoms/ArticleFeedImage/ArticleFeedImage'
import ArticleFeedTitle from '../../atoms/ArticleFeedTitle/ArticleFeedTitle'
import ArticleTags from '../../molecules/ArticleTags/ArticleTags'

const ArticleFeedList = ({ article = null, useExcerpt = true }: any) => {
  const image = article?.thumbnailUrl ?? article?.featuredImageUrl?.url ?? '/images/thumb.png'

  return (
    article && (
      <div>
        <Card sx={{ mt: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={10}>
                  <Grid item xs={8}>
                    <ArticleFeedTitle
                      id={article.wid}
                      title={article.title}
                      variantType="subtitle1"
                    />
                    <ArticleDate date={article.date} />
                    <ArticleTags
                      categories={article.categories}
                      tags={article.tags}
                      resins={article.resins}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ArticleFeedImage id={article.wid} imageUrl={image} />
                  </Grid>
                </Grid>
              </Box>
              {useExcerpt ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <ArticleFeedExcerpt excerpt={article.excerpt} id={article.wid} />
                </Box>
              ) : null}
            </CardContent>
          </Box>
        </Card>
      </div>
    )
  )
}

export default ArticleFeedList
