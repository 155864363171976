import React from 'react'
import Typography from '@mui/material/Typography'
import moment from 'moment'

const ArticleDate = (props: any) => {
  const { date } = props

  return (
    <Typography variant="caption" mr="10px" color="rgba(0, 0, 0, 0.54)">
      {moment(date).format('YYYY.MM.DD')}
    </Typography>
  )
}

export default ArticleDate
