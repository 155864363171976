import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AppBar, Tab, Tabs } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useRouter } from 'next/router'
import SearchForm from '@/containers/organisms/SearchForm/SearchForm'
import { MarketLatestResponse } from '@/infrastructure-v2/plabase/market/market'
import { GetItemSearchConditionsResponse } from '@/infrastructure-v2/plabase/plastic/item/search'
import Article from './Article'

type Props = {
  header: any
  articles: any
  market: MarketLatestResponse
  searchConditions: GetItemSearchConditionsResponse
}

function Home(props: Props) {
  const router = useRouter()
  const [tabValue, setTabValue] = useState('news')

  useEffect(() => {
    const { tab } = router.query
    if (Array.isArray(tab)) {
      setTabValue(tab[0])
    } else {
      setTabValue(tab || 'news')
    }
  }, [router.query])

  const handleChange = (_event: any, value: any) => {
    setTabValue(value)
    window.scrollTo(0, 1)
  }

  const classes = useStyles({})

  return (
    <>
      <div className={classes.root}>
        <AppBar
          position="static"
          color="default"
          className={props.header.hideHeader ? classes.hideTab : classes.showAppbar}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            classes={{ indicator: classes.indicator }}
            variant="fullWidth"
          >
            <Tab label="記事を読む" value="news" />
            <Tab label="材料を検索する" value="search" />
          </Tabs>
        </AppBar>
        {tabValue === 'news' && <Article articles={props.articles} market={props.market} />}
        {tabValue === 'search' && <SearchForm searchConditions={props.searchConditions} />}
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      margin: `0px ${theme.spacing(1)}`,
    },
    showAppbar: {
      position: 'sticky',
      top: '65px',
    },
    hideTab: {
      display: 'none',
    },
    indicator: {
      transition: 'none',
    },
  }),
)

const mapStateToProps = (state: any) => ({
  header: state.header,
})

export default connect(mapStateToProps)(Home)
